import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import L from 'leaflet';
import Papa from 'papaparse';
import './MapComponent.css'
import BottomSheet from './BottomSheet'; 

const MapComponent = () => {
  const [shops, setShops] = useState([]);
  const [selectedShop, setSelectedShop] = useState(null);


  useEffect(() => {
    Papa.parse(`${process.env.PUBLIC_URL}/Liste_sites_Leroy_Merlin_2024_with_coordinates3.csv`, {
      download: true,
      header: true,
      complete: (result) => {
        // Filtrer les entrées où la latitude ou la longitude sont null, undefined, ou des chaînes vides
        const validShops = result.data.filter(shop => shop.latitude && shop.longitude);
        setShops(validShops);
      }
    });
  }, []);
  
  const handleMarkerClick = (shop) => {
    console.log(shop)
    setSelectedShop(shop); // Met à jour l'état avec le magasin cliqué
  };

  const handleClose = () => {
    setSelectedShop(null); // Remet l'état à null, ce qui fermera le bottom sheet
  };

  useEffect(() => {
    console.log(shops);
  }, [shops]);

  return (
    <>
   <MapContainer center={[46.2276, 2.2137]} zoom={6} style={{ height: '100vh', width: '100%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
       {shops.map((shop, index) => (
        <Marker
          key={index}
          position={[shop.latitude, shop.longitude]}
          icon={new L.Icon({
            iconUrl: 'leroymerlin.png',
            iconSize: [40, 60],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [41, 41]
          })}
          eventHandlers={{
            click: () => handleMarkerClick(shop),
          }}
       />
      
        
      ))} 
    </MapContainer>
          <BottomSheet shop={selectedShop} onClose={handleClose} />
</>



  ); 
};

export default MapComponent;
