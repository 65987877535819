// BottomSheet.js
import React from 'react';
import './BottomSheet.css';

const BottomSheet = ({ shop, onClose }) => {
  if (!shop) return null;

  const handleOpenGoogleMaps = () => {
    const query = encodeURIComponent(`${shop['Adresse_']} ${shop['Ville']}, ${shop['Code_postal']}`);
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${query}`;
    window.open(googleMapsUrl, '_blank');
  };

  return (
    <div className={`bottom-sheet ${shop ? 'open' : ''}`}>
      <div className="bottom-sheet-header">
        <img src="Leroy_Merlin.svg.png" alt="Logo" className="bottom-sheet-logo" />
        <div className="bottom-sheet-info">
          <h2>{shop['Libéllé_magasin_complet']}</h2>
          <p>{shop['Adresse_']}</p>
          <p>{shop['Ville']}, {shop['Code_postal']}</p>
          <button onClick={handleOpenGoogleMaps} className="google-maps-btn">
            Ouvrir dans Google Maps
          </button>
        </div>
        <button onClick={onClose} className="close-btn" aria-label="Fermer">
          {/* &#10005;  */}
        </button>
      </div>
    </div>
  );
};

export default BottomSheet;
